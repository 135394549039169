@use "./stylesheets/abstracts/mixins";
@use "./stylesheets/abstracts/functions";

@import 'stylesheets/main';

html {
    -webkit-tap-highlight-color: rgba(201, 224, 253, 0);
}

body {
    overflow: hidden;
    font-size: var(--global-font-size);
    font-family: 'text-base';
}

button {
    background-color: white;
}

.main {
    width: 100%;
    height: 100vh;
}

.button {
    padding: 10px;
    margin-right: 10px;
    font-size: 28px;
}

.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.btn-reset {
    position: absolute;
    left: functions.scaleW(80px);
    bottom: functions.scaleW(62px);
    font-size: functions.scaleW(35px);
    border: solid 2px black;
    font-weight: 600;
    padding: functions.scaleW(20px);
    z-index: var(--zindex-btn-reset);
    cursor: pointer;
}

.btn-primary {
    font-size: functions.scaleW(35px);
    font-weight: 600;
    padding: functions.scaleW(20px);
    color: var(--tap-color);
    background-color: rgba(29, 29, 27, 1.0);
    z-index: var(--zindex-btn-reset);
    text-transform: uppercase;
    cursor: pointer;    
}
.btn-primary:hover {
    color: var(--tap-color-hover);
    text-decoration: none;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

:root {
    
    --coef: 1; //0.66666
    --coef-w: 1; //0.66666
    --coef-h: 1; //0.66666

    --original-width: 3840px;
    --original-height: 2160px;

    --global-font-size: 16px;

    --text-primay: rgba(178, 178, 178, 1.0);
    --text-secondary: rgba(0, 0, 0, 1.0);
    --text-tertiary: rgba(255, 255, 255, 1.0);

    --tap-color: rgba(247,216,76,1.0);
    --tap-color-hover: rgba(255,255,255,1.0);
    
    --offer-unselected: rgba(200,200, 200,1.0);
    --offer-360-primary: rgba(130, 53, 140, 1.0);
    --offer-360-secondary: rgba(130, 53, 140, 0.6);

    --offer-pax-primary: rgba(199, 12, 15, 1.0);
    --offer-pax-secondary: rgba(199, 12, 15, 0.5);

    /*
    --offer-pax-primary: rgba(277, 6, 19, 1.0);
    --offer-pax-secondary: rgba(277, 6, 19, 0.5);
    */ 
    --offer-skywave-primary: rgba(37,78,157,1.0);
    --offer-skywave-secondary: rgba(37,78,157,0.5);

    --offer-msp-primary: rgba(106,194,186,1.0);
    --offer-msp-secondary: rgba(106,194,186,0.7);

    --offer-app-primary: rgba(242,143,23,1.0);
    --offer-app-secondary: rgba(242,143,23,0.7);

    --offer-diy-primary: rgba(102,179,45,1.0);
    --offer-diy-secondary: rgba(102,179,45,0.5);

    --bookmarks-bg-color: rgba(217,217,217,1.0);

    --zindex-circle-selected: 505;
    --zindex-circle-nav: 510;
    --zindex-circle-unselected: 501;

    --zindex-header: 2000;
    --zindex-popups: 3000;
    --zindex-btn-reset: 3000;

    --ad-circle-nav: 0.2s;

   

}