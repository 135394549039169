@use "../../stylesheets/abstracts/functions";

.sunburst {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    width: functions.scaleW(1708px);
    height: functions.scaleW(1708px);
    //background: red;
    display: flex;
    justify-content: center;
    align-items: center;

    .sun {
        position: absolute;
    }

    .sun-root, .sun-leaf {
        pointer-events: fill;
        cursor: pointer;
    }

    .offer-buton {
        padding: 0;
        border: 0;
        cursor: pointer;
        width: functions.scaleW(174px);
        height: functions.scaleH(241px);
        //left: functions.scaleH(758px);
        //top: functions.scaleH(730px);
        /*@include mixins.ratioW(margin-right, 68px);*/
        background-size: contain;
        pointer-events: all;
        &.pax, &.pax_linefit {
            background: url("../../../public/images/pax.png") no-repeat;
            background-size: contain;
            transform: scale(1.0);
        }            
        &.display_360, &.display_360_linefit {
            background: url("../../../public/images/360.png") no-repeat;
            background-size: contain;
            transform: scale(1.0);
        }            
        &.app {
            background: url("../../../public/images/app.png") no-repeat;
            background-size: contain;
            transform: scale(1.0);
        }            
        &.msp {
            background: url("../../../public/images/msp.png") no-repeat;
            background-size: contain;
            transform: scale(1.0);
        }            
        &.diy {
            background: url("../../../public/images/diy.png") no-repeat;
            background-size: contain;
            transform: scale(1.0);
        }            
        &.skywave {
            background: url("../../../public/images/psw.png") no-repeat;
            background-size: contain;
            transform: scale(1.0);
        }            
    }    


}

#falback {
    position: absolute;
    left: 2000;
    z-index: 9999;
    background-color: aqua;
}

.svgText {
    opacity: 0;
    -webkit-touch-callout: none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
    pointer-events: none;
}

.svgText.selected {
    display: none;
}


