@use "../stylesheets/abstracts/functions";

.test {
    position: absolute;
    top:0;
    bottom: 0;
    background-color: red;
    width: 100%;
    height: 100vh;
    z-index: 0;
}



.iso {
    transform: matrix(0.87, 0.5, 0, 1, 0, 0);
}

.text {
    position: absolute;
    top : 0px;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 100;
}


.text h1 {
    position: relative;
    top: 80px;
    left: -22px;
    margin: 0;
    padding: 0;
    background-color: yellow;

}

.toto {
    display: flex;
}

.rotate {
    display: flex;
    width: 40px;
    aspect-ratio: 1/1;
    background-color: rosybrown;
    border-radius: 10px;
    transform: rotate(35deg);
    transform-origin: top left;
    transform-box: border-box;
    z-index: 100;
}

.test-svg {
    position: absolute;
    background-color: yellow;
    scale: 1.0;
    transform-origin: center;
    left: 500px;
    top: 500px;
 
}

.anim-playground {
    width: 100%;
    height: 100vh;
}

.home {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
        font-size: functions.scaleW(60px);
    }

    .fs {
        margin-top: 40px;
    }
}