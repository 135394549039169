@use "../../../stylesheets/abstracts/functions";

.brick-selector-group {
}

.brick-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: functions.scaleH(300px);;
    pointer-events: none;
    overflow: hidden;

    .group-with-wall {
        position: absolute;
        background: url("../../../assets/images/birck-wall-yellow.png") no-repeat;
        background-size: contain;        
        width: functions.scaleW(234px);    
        height: functions.scaleH(183px);       
        .brick-block {
            pointer-events: all;
            position: absolute;
            left: functions.scaleW(150px);
            top: functions.scaleH(-40px);
        }         
    }


}
