@use "../../stylesheets/abstracts/functions";

.display-zone {
    position: absolute;
    z-index: 30;

    .display-place {
        position: absolute;
        left: functions.scaleW(2432px);
        top: functions.scaleH(1256px);
        z-index: 1;
        pointer-events: none;
    }    
    
    .pm {
        position: absolute;
        left: functions.scaleW(2560px);
        top: functions.scaleH(1461px);
        z-index: 10;
        pointer-events: all;
        cursor: pointer;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(-123px);
            top: functions.scaleH(268px);
            z-index: 1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }

    .dev {
        position: absolute;
        left: functions.scaleW(3230px);
        top: functions.scaleH(1836px);
        z-index: 20;
        pointer-events: all;
        cursor: pointer;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(-178px);
            top: functions.scaleH(94px);
            z-index: 1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }    

    .ops {
        position: absolute;
        left: functions.scaleW(3371px);
        top: functions.scaleH(1468px);
        z-index: 15;
        pointer-events: all;
        cursor: pointer;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(-76px);
            top: functions.scaleH(191px);
            z-index: 1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }     

    .sla {
        position: absolute;
        left: functions.scaleW(3417px);
        top: functions.scaleH(1177px);
        z-index: 5;
        pointer-events: all;
        cursor: pointer;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(8px);
            top: functions.scaleH(228px);
            z-index: 1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }

    h1 {
        position: absolute;
        left: functions.scaleW(2862px);
        top: functions.scaleH(1774px);
        margin: 0;
        padding: 0;
        font-size: functions.scaleW(74px);
        color: white;
        font-weight: 800;
        text-transform: uppercase;
    }

    .ground-text {
        transform: rotate(30deg) skewX(-30deg);
        letter-spacing: functions.scaleW(12px);
        z-index: 2;        
    }
}

