@use "../../stylesheets/abstracts/functions";

.aircraft-selector {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 10;
    //pointer-events: none;

    .cabin {
        position: absolute;
        left: functions.scaleW(1902px);
        top: functions.scaleH(680px);
        z-index: 18;
        filter: drop-shadow(-26px 26px 70px rgba(0, 0, 0, 0.25));
        opacity: 0;
        

        .cabin-crew {
            position: absolute;
            left: functions.scaleW(436px);
            top: functions.scaleH(241px);
            pointer-events: all;
            cursor: pointer;
            pointer-events: all;
        }

        .cabin-pax {
            position: absolute;
            left: functions.scaleW(198px);
            top: functions.scaleH(288px);
            pointer-events: all;
            cursor: pointer;
            pointer-events: all;
        }
    }

    .aircraft {
        position: absolute;
        transform: scale(1.4);
        left: functions.scaleW(1713px);
        top: functions.scaleH(98px);
        pointer-events: none;
    }

    .aircraft-shadow-2 {
        position: absolute;
        left: functions.scaleW(1276px);
        top: functions.scaleH(718px);
        pointer-events: none;
    }

    .aircraft-cabin {
        position: absolute;
        left: functions.scaleW(2397px);
        top: functions.scaleH(627px);
        cursor: pointer;
        pointer-events: all;

    }

    .aircraft-cabin-focus {
        position: absolute;
        left: functions.scaleW(2248px);
        top: functions.scaleH(446px);
        pointer-events: none;
    }

    .aircraft-server {
        position: absolute;
        left: functions.scaleW(1744px);
        top: functions.scaleH(906px);
        cursor: pointer;
        pointer-events: all;
    }

    .aircraft-antenna {
        position: absolute;
        left: functions.scaleW(2805px);
        top: functions.scaleH(424px);
        cursor: pointer;
        pointer-events: all;
        z-index: 600;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(-180px);
            top: functions.scaleH(-80px);
            z-index:1;
        }
    }

    .aircraft-server-focus {
        position: absolute;
        left: functions.scaleW(1610px);
        top: functions.scaleH(772px);
        pointer-events: none;
    }
}