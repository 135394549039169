@use "../../stylesheets/abstracts/functions";

.criteria-list {
 
    position: absolute;
    width: functions.scaleW(1658px);
    height: functions.scaleH(291px);
    left: functions.scaleW(320px);
    top: functions.scaleW(1821px);
    border: solid #E30613 5px;
    border-radius: functions.scaleW(80px);
    /*background-color: rgba(255,0,0,0.1);*/
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 100;
    
    padding: functions.scaleW(20px);
    margin: 0;

    .offer-buton {
        cursor: default;
        min-width: functions.scaleW(174px);
        min-height: functions.scaleH(241px);
    }

    .criteria-items {
        display: flex;
        margin-left: functions.scaleW(10px);
    }

    .mini-criteria {
        display: flex;
        margin: 0;
        padding: 0;
        opacity: 1.0;
        mix-blend-mode: overlay;

        .criteria {
            position: absolute;
            margin: 0;
            padding: 0;            
            position: static;
            transform: scale(0.8);
            margin-left: functions.scaleW(100px);
        }
        
        .brick-block {
            position: absolute;
            left: inherit;
            top: functions.scaleH(-14px);
            z-index: 2;
            transform: scale(0.5);
        }
    }

    .title  {
        font-family: 'text-base';
        font-style: normal;
        font-weight: 800;
        line-height: functions.scaleW(110px);
        font-size: functions.scaleW(100px);
        padding-left: functions.scaleW(40px);

        &.pax, &.pax_linefit {
            color: var(--offer-pax-primary);
        }

        &.app {
            color: var(--offer-app-primary);
        }

        &.msp {
            color: var(--offer-msp-primary);
        }

        &.diy {
            color: var(--offer-diy-primary);
        }

        &.skywave {
            color: var(--offer-skywave-primary);
        }

        &.display_360, &.display_360_linefit {
            color: var(--offer-360-primary);
        }        
    }

}