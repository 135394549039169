@use "../stylesheets/abstracts/functions";

.circle-offers {
    
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;

    .sunburst-container {
        position: absolute;
        top: 0;
        left: 0;
    }
    .switch {
        position: absolute;
        left: functions.scaleW(70px);
        top: functions.scaleH(1990px);
        z-index: 9500;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        gap: functions.scaleW(30px);

        .switch-bg {            
            //padding: functions.scaleW(10px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: functions.scaleW(48px);
            width: functions.scaleW(180px);
            height: functions.scaleH(80px);
            background-color: gray;

            .switch-button {
                margin: 0 10px;
                background: var( --tap-color);
                border-radius: functions.scaleW(48px);
                border: solid 2px black;
                width: functions.scaleW(62px);
                height: functions.scaleH(62px);
            }    
        }

        .switch-bg.selected {
            justify-content: flex-end;
        }

        h1 {
            font-size: functions.scaleW(44px);
            color: rgba(58, 58, 58, 0.5);
            text-transform: uppercase;
        }
    
        .selected {
            color: rgba(58, 58, 58, 1.0);        
        }        
    }

}
