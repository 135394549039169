@use "../stylesheets/abstracts/functions";

.configurator {

    display: flex;
    justify-content: center;
    align-items: flex-end;

    .aircraft-ground {
        position: absolute;
        left: functions.scaleW(184px);
        top: functions.scaleH(359px);

        background: url("../assets/images/ground.png") no-repeat;
        background-size: contain;
        width: functions.scaleW(1948px);
        height: functions.scaleW(1132px);
        pointer-events: none;
    }

    .aircraft-ground-shadow {
        position: absolute;
        background: url("../assets/images/ground-shadow.png") no-repeat;
        background-size: contain;
        width: functions.scaleW(1044px);
        height: functions.scaleW(1291px);
        left: functions.scaleW(181px);
        top: functions.scaleH(1072px);
        pointer-events: none;

    }

    /*
    .ground {
        position: absolute;
        width: 800px;
        height: 800px;
        background-color: red;
        z-index: 2;
        top: 100px;
        left: 100px;
        transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);
    }


    .front {
        position: absolute;
        width: 300px;
        height: 800px;
        background-color: red;
        z-index: 2;
        top: 100px;
        left: 100px;
        transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);

    }

    .cube {
        position: absolute;
        left: functions.scaleW(955px);
        top: functions.scaleH(1442px);
        width: 300px;
        height: 300px;
    }


    .face {
        transform-origin: 0 0;
        position: absolute;
        width: functions.scaleW(1020px);
        height: functions.scaleW(1420px);
        opacity: 1;
    }

    .top {
        background: #B2B2B2;
        -ms-transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
        -webkit-transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
        transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
        font-size: 64px;
    }


    .front {
        background: blue;
        -ms-transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
        -webkit-transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
        transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
        font-size: 64px;
        width: functions.scaleW(1220px);
        height: functions.scaleW(200px);
    }

    */

    .side {
        background: linear-gradient(88deg, #D7DCE0 31.38%, rgba(215, 220, 224, 0) 52.67%);
        /*
        -ms-transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
        -webkit-transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
        transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
        */
        transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -260px);
        //transform:rotatex(45deg) rotateZ(45deg) translateZ(0em);
        font-size: 64px;
        width: functions.scaleW(520px);
        height: functions.scaleW(1150px);
    }

    .aircraft.configurator {
        position: absolute;
        left: functions.scaleW(138px);
        top: functions.scaleH(24px);
        z-index: 100;
        pointer-events: none; 
    }

    
    .aircraft-shadow {
        position: absolute;
        left: functions.scaleW(183px);
        top: functions.scaleH(552px);
        width: functions.scaleW(1202px);
        height: functions.scaleH(742px);;
        z-index: 99;
        background-image: url("../assets/images/aircraft-shadow-1.png");
        background-repeat: no-repeat;
        background-size: cover;
        mix-blend-mode: multiply;
        background-blend-mode: multiply;   
        pointer-events: none; 
    }

    .config-progress {
        
        position: absolute;
        left: functions.scaleW(205px);
        top: functions.scaleH(1355px);
        transform: skewY(30deg);
        color: #B2B2B2;
        font-size: functions.scaleW(54px);
        z-index: 1002;
        text-align: right;
        h1, h2 {
            margin: 0;
            padding: 0;    
        }
        
        .complete {
            display: block;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) var(--complete-pct), rgba(178, 178, 178, 1.0) var(--complete-pct));
            opacity: 0.5;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;            
        }
    }
}

