@use "../../stylesheets/abstracts/mixins";
@use "../../stylesheets/abstracts/functions";

.criteria-selector {
    position: absolute;
    cursor: pointer;
    
    .brick-wall {
        position: absolute;
        left: functions.scaleW(-42px);
        top: functions.scaleH(-100px);
        width: functions.scaleW(264px);
        height: functions.scaleH(202px);
        background: url("../../assets/images/brick-wall.png") no-repeat;
        background-size: contain;    
        pointer-events: all;            
    }    

    .brick-block {
        /*visibility: hidden;*/
    }

    .ground {
        position: absolute;
        width: functions.scaleW(554px);
        height: functions.scaleH(298px);
        z-index: 0;
        pointer-events: none;
        .back-image {
            background: url("../../assets/images/criteria-bg-off.png") no-repeat;
            background-size: contain;
        }
    
        .front-image {
            background: url("../../assets/images/criteria-bg-selected.png") no-repeat;
            background-size: contain;
        } 
    
    }

    .criteria-desc {
        position: absolute;    
        left: functions.scaleW(352px);
        top: functions.scaleH(300px);
        width: functions.scaleW(270px);
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;

        .criteria-title {
            font-size: functions.scaleW(25px);
            background-color: white;
            margin: 0;
            width: functions.scaleW(250px);
            padding: functions.scaleW(15px);
            background-color: white;
            min-height: functions.scaleH(80px);
        }

        .arrow {
            width: functions.scaleW(20px);;
            height: functions.scaleH(15px);
            background: no-repeat url("../../assets/images/criteria-arrow.png");
            background-size: contain;
        }
    

        .criteria-info {
            font-size: functions.scaleW(23px);
            width: functions.scaleW(270px);
            background-color: black;
            color: white;
            /*padding: functions.scaleW(25px);        */
            padding: functions.scaleW(15px);
            margin: 0;
        }
    
    }

}