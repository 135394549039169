@use "../../stylesheets/abstracts/functions";

.bookmarks-panel {
    position: absolute;    
    width: functions.scaleW(1271px);
    right: functions.scaleW(-1271px);
    height: 100vh;
    z-index: 25000;
    background-color: var(--bookmarks-bg-color);
    display: flex;
    flex-direction: column;    
    justify-content: space-between;
    align-items: center;
    visibility: hidden;

    h1 {
        text-transform: uppercase;
        font-weight: 800;
        color: white;
        font-size: functions.scaleW(128px);
        margin: 0;
        padding: 0;
        margin-top: functions.scaleH(60px);
    }

    ul {
        overflow: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        width: 95%;
        height: 80%;
        gap: functions.scaleW(24px);
        border-top: solid 4px white;
        border-bottom: solid 4px white;
        padding: functions.scaleW(32px);
        margin: 0;
        
    }

    ul::-webkit-scrollbar {
        display: none;
    }

    li {
        margin: 0;
        padding: functions.scaleW(32px);
        display: flex;
        align-items: center;
        justify-content: space-between;        
        width: 100%;
        list-style: none;
        cursor: pointer;
        background-color: white;
        h1 {
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 0;
            padding: 0;
            font-weight: 500;
            font-size: functions.scaleW(32px);        
            color: black;
            text-transform: none;
        }
        border: solid 4px black;
        border-radius: functions.scaleW(24px);
        .offer-buton {
            padding: 0;
            border: 0;
            cursor: pointer;
            width: functions.scaleW(97px);
            height: functions.scaleH(134px);
        }        
    }

    .btn-reset {
        position: static;
        margin-bottom: functions.scaleH(40px);
    }

    .btn-delete {
        background: url("../../assets/images/close.svg") no-repeat;
        background-size: contain;
        width: functions.scaleW(48px);
        height: functions.scaleH(48px);
    }
}