@use "../../stylesheets/abstracts/functions";

.airline-place {
    width: functions.scaleW(1639px);
    height: functions.scaleH(796px); // 1022 - 796
    background: url("../../assets/images/airline-place.png") no-repeat;
    background-size: contain;
}

.airline-place-tower {
    width: functions.scaleW(380px);
    height: functions.scaleH(684px); // 1022 - 796
    background: url("../../assets/images/airline-place-tower.png") no-repeat;
    background-size: contain; 
}

.airline-place-tree {
    width: functions.scaleW(111px);
    height: functions.scaleH(154px); // 1022 - 796
    background: url("../../assets/images/tree.png") no-repeat;
    background-size: contain; 
}

.display-place {
    width: functions.scaleW(1991px);
    height: functions.scaleH(1140px);
    background: url("../../assets/images/display-place.png") no-repeat;
    background-size: contain;
}

.cabin {
    width: functions.scaleW(689px);
    height: functions.scaleH(689px);
    background: url("../../assets/images/cabin.png") no-repeat;
    background-size: contain;
}

.cabin-pax {
    width: functions.scaleW(120px);
    height: functions.scaleH(236px);

    .back-image {
        background: url("../../assets/images/cabin-maintenance-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/cabin-maintenance-selected.png") no-repeat;
        background-size: contain;
    }
}

.cabin-crew {
    width: functions.scaleW(197px);
    height: functions.scaleH(281px);

    .back-image {
        background: url("../../assets/images/cabin-crew-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/cabin-crew-selected.png") no-repeat;
        background-size: contain;
    }
}


.satellite {
    width: functions.scaleW(536px);
    height: functions.scaleH(461px);

    .back-image {
        background: url("../../assets/images/satelitte-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/satelitte-selected.png") no-repeat;
        background-size: contain;
    }
}


.zone-focus-1 {
    width: functions.scaleW(530px);
    height: functions.scaleH(252px);
    background: url("../../assets/images/zone-focus-1.png") no-repeat;
    background-size: contain;
}

.factory {
    width: functions.scaleW(821px);
    height: functions.scaleH(525px);

    .back-image {
        background: url("../../assets/images/factory-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/factory-selected.png") no-repeat;
        background-size: contain;
    }
}


.certif {
    width: functions.scaleW(281px);
    height: functions.scaleH(340px);

    .back-image {
        background: url("../../assets/images/certif-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/certif-selected.png") no-repeat;
        background-size: contain;
    }
}


.customer {
    width: functions.scaleW(300px);
    height: functions.scaleH(367px);

    .back-image {
        background: url("../../assets/images/curstormer-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/customer-selected.png") no-repeat;
        background-size: contain;
    }
}

.sla {
    width: functions.scaleW(325px);
    height: functions.scaleH(424px);

    .back-image {
        background: url("../../assets/images/noc-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/noc-selected.png") no-repeat;
        background-size: contain;
    }
}


.pm {
    width: functions.scaleW(261px);
    height: functions.scaleH(464px);

    .back-image {
        background: url("../../assets/images/pm-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/pm-selected.png") no-repeat;
        background-size: contain;
    }

}


.dev {
    width: functions.scaleW(275px);
    height: functions.scaleH(255px);

    .back-image {
        background: url("../../assets/images/dev-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/dev-selected.png") no-repeat;
        background-size: contain;
    }
}


.ops {
    width: functions.scaleW(335px);
    height: functions.scaleH(390px);

    .back-image {
        background: url("../../assets/images/ops-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/ops-selected.png") no-repeat;
        background-size: contain;
    }
}

.aircraft-antenna {
    width: functions.scaleW(182px);
    height: functions.scaleH(81px);
    .back-image {
        background: url("../../assets/images/antenna-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/antenna-selected.png") no-repeat;
        background-size: contain;
    }
}


.aircraft-server {
    width: functions.scaleW(191px);
    height: functions.scaleH(249px);
    background: url("../../assets/images/aircraft-server-selected.png") no-repeat;
    background-size: contain;
    mix-blend-mode: multiply;
    background-blend-mode: multiply;    
}



.aircraft-cabin {
    width: functions.scaleW(217px);
    height: functions.scaleH(225px);
    background: url("../../assets/images/aircraft-cabin-selected.png") no-repeat;
    background-size: contain;
    mix-blend-mode: multiply;
    background-blend-mode: multiply;    
}

.aircraft-server-focus {
    width: functions.scaleW(469px);
    height: functions.scaleH(505px);
    background: url("../../assets/images/aircraft-server-focus.png") no-repeat;
    background-size: contain;    
    visibility: hidden;
}


.aircraft-cabin-focus {
    width: functions.scaleW(514px);
    height: functions.scaleH(597px);
    background: url("../../assets/images/aircraft-cabin-focus.png") no-repeat;
    background-size: contain;
    visibility: hidden;
}

.aircraft-shadow-2 {
    width: functions.scaleW(1720px);
    height: functions.scaleH(968px);
    background: url("../../assets/images/aircraft-shadow-2.png") no-repeat;
    background-size: contain; 
    mix-blend-mode: multiply;
    background-blend-mode: multiply;
}