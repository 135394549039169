@use "../stylesheets/abstracts/functions";
@use "../stylesheets/abstracts/mixins";


header {
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    display: flex;
    z-index: var(--zindex-header);
    justify-content: space-between;
    pointer-events: none;

    .logo {
        pointer-events: all;
        content: url("../assets/images/logo.png");        
        @include mixins.ratioW(width, 390px);
        @include mixins.ratioW(height, 148px);
        @include mixins.ratioW( margin-left, 73px);
        @include mixins.ratioH( margin-top, 78px);
    }

    .nav {
        display: flex;
        flex-direction: row;
        justify-content: right;
        pointer-events: all;
        @include mixins.ratioH(margin-top, 120px);
        @include mixins.ratioW(margin-right, 73px);
        @include mixins.ratioW(gap, 80px);

        .bookmark {
            content: url("../assets/images/bookmark-selected.svg");
            @include mixins.ratioW(width, 106px);
            @include mixins.ratioW(height, 106px);
            background-size: contain;
        }

        .circles {
            content: url("../assets/images/circles.svg");
            @include mixins.ratioW(width, 106px);
            @include mixins.ratioW(height, 106px);
            background-size: contain;
        }

        .home {
            content: url("../assets/images/home.svg");
            @include mixins.ratioW(width, 106px);
            @include mixins.ratioW(height, 106px);
            background-size: contain;
        }
 
    }

    .hiddenFullScreen {
        width: functions.scaleW(300px);
        height: functions.scaleH(150px);
        pointer-events: all;
        cursor: pointer;
        z-index: 18000;
    }
}



