/*
body {
    scrollbar-width: none;
}
*/
.full-screen {
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  #intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #panels #panels-container {
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    overflow: hidden;
    background-color: #ddd;
  }

  #panels #panels-container .panel {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #333;
    text-align: left;
    border-right: 1px solid #f00;
  }