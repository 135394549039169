@use "../../stylesheets/abstracts/functions";

.airline-zone {
    position: absolute;
    z-index: 20;

    .airline-place {
        position: absolute;
        left: functions.scaleW(-583px);
        top: functions.scaleH(771px);
        z-index: 1;
        pointer-events: none;
    }

    .airline-place-tower {
        position: absolute;
        left: functions.scaleW(39px);
        top: functions.scaleH(548px);
        z-index: 200;
        pointer-events: none;
    }    

    .airline-place-tree {
        position: absolute;
        left: functions.scaleW(841px);
        top: functions.scaleH(931px);
        z-index: 201;
        pointer-events: none;
    }    

    .factory {
        position: absolute;
        left: functions.scaleW(75px);
        top: functions.scaleH(319px);
        z-index: 10;
        pointer-events: all;
        cursor: pointer;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(430px);
            top: functions.scaleH(200px);
            z-index:1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }

    .certif {
        position: absolute;
        left: functions.scaleW(452px);
        top: functions.scaleH(771px);
        pointer-events: all;
        z-index: 20;
        cursor: pointer;
        .zone-focus-1 {
            position: absolute;
            left: functions.scaleW(-120px);
            top: functions.scaleH(120px);
            z-index:1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }

    .customer {
        position: absolute;
        left: functions.scaleW(439px);
        top: functions.scaleH(1097px);
        pointer-events: all;
        cursor: pointer;
        z-index: 30;
        .zone-focus-1 {
            position: absolute;
            left: functions.scaleW(-90px);
            top: functions.scaleH(120px);
            z-index:1;
        }
        .back-image { z-index: 2; }
        .front-image { z-index: 2; }
    }

    h1 {
        position: absolute;
        left: functions.scaleW(31px);
        top: functions.scaleH(1292px);
        margin: 0;
        padding: 0;
        font-size: functions.scaleW(100px);
        color: white;
        font-weight: 800;
        text-transform: uppercase;
    }

    .ground-text {
        transform: rotate(30deg) skewX(-30deg);
        letter-spacing: functions.scaleW(12px);
        z-index: 2;
    }
    
}

