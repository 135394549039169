



@use "../../stylesheets/abstracts/functions";



.podium {
    width: functions.scaleW(352px);
    height: functions.scaleH(483px); // 1022 - 796
    background: url("../../assets/images/podium.png") no-repeat;
    background-size: contain;
}

.podium-mask {
    width: functions.scaleW(352px);
    height: functions.scaleH(412px); // 1022 - 796
    background: url("../../assets/images/podium-mask.png") no-repeat;
    background-size: contain;
}

.podium-nav {
    .offer-nav-buttons {
        gap: 0px;
    }
}

.podium-button {
    margin: 0px;
    margin-right: functions.scaleW(-60px);
    padding: 0px;
    display: flex;
    flex-direction: column;
    width: functions.scaleW(352px);
    height: functions.scaleH(205px);

    .podium {
        position: absolute;
    }

    .offer-buton {
        position: absolute;
        top: functions.scaleH(-110px);
        left: functions.scaleW(85px);
    }
    .podium-mask {
        position: absolute;
        top: functions.scaleH(90px);
    }
}