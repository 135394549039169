@use "../../stylesheets/abstracts/mixins";

.debug-panel {
    position: absolute;
    z-index: 9999;

    @include mixins.ratioW(left, 40px);
    @include mixins.ratioW(bottom, 150px);
    @include mixins.ratioW(padding, 10px);
    @include mixins.ratioW(font-size, 30px);

    ul, li {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    li {
        @include mixins.ratioW(margin-bottom, 2px);
    }
}

// TODO: Remove when finished


/*

.debug-offer-bg {
    position: absolute;
    background: url("../../assets/images/model-offer.jpg") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100vh;
    opacity: 0.2;
    z-index: 0;
}
.debug-configurator-bg {
    position: absolute;
    background: url("../../public/images/model-if2.jpg") no-repeat;
    background-size: contain;
    width: 100%;
    height: 100vh;
    opacity: 0.2;
    z-index: 0;
    visibility: hidden;
}
*/
