@use "../../stylesheets/abstracts/functions";

.aircraft-type-selector {
    position: absolute;
    left: functions.scaleW(530px);
    top: functions.scaleH(1194px);
    z-index: 100;
    
    .switch {
        display: flex;
    }

    .switch-bg {
        position: relative;
        background: url("../../assets/images/drag-bg.png") no-repeat;
        background-size: contain;
        width: functions.scaleW(179px);
        height: functions.scaleH(105px);
    }


    .switch-button {
        position: relative;
        left: functions.scaleW(-160px);
        top: functions.scaleH(-7px);
        background: url("../../assets/images/drag-btn.png") no-repeat;
        background-size: contain;
        width: functions.scaleW(71px);
        height: functions.scaleH(63px);
    }

    h1 {
        font-size: functions.scaleW(44px);
        color: rgba(58, 58, 58, 0.5);
        text-transform: uppercase;
    }

    .selected {
        color: rgba(58, 58, 58, 1.0);        
    }

    .switch-left-label {
        position: absolute;
        left: functions.scaleH(-204px);
        top: functions.scaleH(-116px);
        transform: rotate(30deg) skewX(-30deg);

    }

    .switch-right-label {
        position: absolute;
        left: functions.scaleH(171px);
        top: functions.scaleH(94px);
        transform: rotate(30deg) skewX(-30deg);
    }


}