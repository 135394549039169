@use "../../stylesheets/abstracts/functions";

.popup-manager {
    position: absolute;
    z-index: var(--zindex-popups);
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    pointer-events: none;
}
.popup {
    pointer-events: all;
    position: absolute;
    left: functions.scaleW(2560px);
    top: functions.scaleH(320px);
    background-color: white;
    z-index: var(--zindex-popups);
    width: functions.scaleW(1200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px calc(40px * var(--coef-w)) 0px calc(40px * var(--coef-w)); 
    border: calc(16px * var(--coef-w)) solid #030300;
    border-radius: calc(40px * var(--coef-w));
    box-shadow: calc(-26px * var(--coef-w)) calc(26px * var(--coef-w)) calc(60px * var(--coef-w)) rgba(0, 0, 0, 0.25);
    opacity: 0;
    
    .title {
        text-align: left;
        font-size: functions.scaleW(64px);
        font-weight: 600;
        margin-bottom: functions.scaleW(64px);
        width: functions.scaleW(1100px);
    }    

    nav {
        margin: calc(128px * var(--coef-w));
    }

    .actions {
        position: absolute;       
        top: functions.scaleH(-72px);
        right: functions.scaleW(-36px);        
        width: functions.scaleW(298px);
        height: functions.scaleH(147px);
        background: url("../../assets/images/popup-frise.png") no-repeat;
        background-size: contain;
        display: flex;
        justify-content: flex-end;
        gap: functions.scaleW(62px);
        z-index: 6;

        button {
            margin-top: functions.scaleH(28px);
            cursor: pointer;
        }

        .btn-add-favorite {
            background: url("../../assets/images/bookmark.svg") no-repeat;
            width: functions.scaleW(49px);
            height: functions.scaleH(66px);
            cursor: pointer;
            background-size: contain;
        }

        .btn-add-favorite.selected {
            background: url("../../assets/images/bookmark-selected.svg") no-repeat;
            background-size: contain;
        }

        .btn-close {
            background: url("../../assets/images/close.svg") no-repeat;
            background-size: contain;
            width: functions.scaleW(66px);
            height: functions.scaleH(66px);
            margin-right: functions.scaleW(46px);
        }
    }    

    article {
        text-align: left;
        width: 100%; 
        h1 {
            margin: 0;
            font-size: functions.scaleW(35px);
            margin-top: functions.scaleH(64px);
            font-weight: 600;
            padding: 0;
        }
        p {
            margin: 0;
            padding: 0;
            font-weight: 400;
            font-size: functions.scaleW(35px);
            margin-bottom: functions.scaleH(64px);
        }

    }

    .swiper {

        width: functions.scaleW(1100px);
        margin-top: functions.scaleH(6px);;
        margin-bottom: functions.scaleH(20px);;
        height: 100%;

        .swiper-pagination {
            bottom: 0;
        }

        .swiper-pagination-bullet {
            background: black;
            width: functions.scaleW(32px);
            height: functions.scaleW(32px);
        }
    }

    .dragZone {
        position: absolute;
        width: functions.scaleW(1180px);
        height: functions.scaleH(240px);
        left: 0px;
        top: functions.scaleH(-50px);
        z-index: 5;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.popup-criteria, .popup-offer {
    display: flex;    
    flex-direction: column;
    justify-content:space-between;
    align-items: center;

    h1 {
        margin-top: functions.scaleH(60px);
        text-align: left;
        width: 100%;
        font-size: functions.scaleW(35px);
        font-weight: 600;
        
    }


    article {
        text-align: left;
        width: 100%;
        font-size: functions.scaleW(35px);
        font-weight: 500;
    }
    
    .btn-primary {
        max-width: 30%;
        margin-bottom: functions.scaleH(60px);
    }
}

.popup-criteria {
    .title {
        text-align: center; 
    }
    article {
        text-align: center;
        h1 {
            margin: functions.scaleH(40px) 0;
            padding: 0;
            text-align: center; 
        }        
    }
}
    
.popup-offer {
    .title {
        display: flex;       
        align-items: center;
        gap: functions.scaleW(40px);
    }

    section {
        display: flex;
        width: functions.scaleW(1100px);
        margin-top: functions.scaleH(80px);
        margin-bottom: functions.scaleH(80px);
        gap: functions.scaleW(20px);
        .content {
            width: 66%;
        }
        .offer-image {
            width: 33%;
            img {
                max-width: functions.scaleW(348px);
            }
        }
    }
}

