@use "../../stylesheets/abstracts/mixins";
@use "../../stylesheets/abstracts/functions";


.back-image {
    position: absolute;
    z-index: 1;
    width: inherit;
    height: inherit;                    
}

.front-image {
    position: absolute;
    z-index: 2;
    width: inherit;
    height: inherit;
    opacity: 0;
}

.brick {
    /*position: absolute;*/
    width: functions.scaleW(130px);
    height: functions.scaleH(100px);

    .back-image {
        background: url("../../assets/images/brick-off.png") no-repeat;
        background-size: contain;
    }

    .front-image {
        background: url("../../assets/images/brick-selected.png") no-repeat;
        background-size: contain;
    }
}

.brick-block {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: functions.scaleH(250px);
    cursor: pointer;

  
}

.criteria {
    position: absolute;
    &.revenues {
        width: functions.scaleW(180px);
        height: functions.scaleH(176px);
    
        .back-image {
            background: url("../../assets/images/revenues-off.png") no-repeat;
            background-size: contain;                
        }
    
        .front-image {
            background: url("../../assets/images/revenues-selected.png") no-repeat;
            background-size: contain;                
        }        
    }

    &.internet_speed {
        width: functions.scaleW(169px);
        height: functions.scaleH(157px);

        .back-image {
            background: url("../../assets/images/internet_speed-off.png") no-repeat;
            background-size: contain;                
        }
    
        .front-image {
            background: url("../../assets/images/internet_speed-selected.png") no-repeat;
            background-size: contain;                
        }        
    }

    &.existing_solution {
        width: functions.scaleW(181px);
        height: functions.scaleH(163px);
        .back-image {
            background: url("../../assets/images/existing_solution-off.png") no-repeat;
            background-size: contain;                                
        }
    
        .front-image {
            background: url("../../assets/images/existing_solution-selected.png") no-repeat;
            background-size: contain;                
        }            
    }

    &.ui_custo {
        width: functions.scaleW(162px);
        height: functions.scaleH(186px);
        .back-image {
            background: url("../../assets/images/ui_custo-off.png") no-repeat;
            background-size: contain;                                
        }
    
        .front-image {
            background: url("../../assets/images/ui_custo-selected.png") no-repeat;
            background-size: contain;                
        }               
    }

    &.airline_control {
        width: functions.scaleW(206px);
        height: functions.scaleH(167px);

        .back-image {
            background: url("../../assets/images/airline_control-off.png") no-repeat;
            background-size: contain;                                
        }
    
        .front-image {
            background: url("../../assets/images/airline_control-selected.png") no-repeat;
            background-size: contain;                
        }   
    }
}

.aircraft {
    width: functions.scaleW(1714px);
    height: functions.scaleH(960px);;
    background: url("../../assets/images/aircraft.png") no-repeat;
    background-size: cover;
}
