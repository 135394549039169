@use "../../../stylesheets/abstracts/mixins";

.image-grid {
    display: grid;
    /*
    grid-template-columns: repeat(15, calc(54px * var(--coef-w)));
    grid-template-rows: repeat(15, calc(54px * var(--coef-w)));
    */
    @include mixins.ratioW(grid-gap, 18px);    
    // background-color: aqua;
    
    figure {
        margin: 0;
        padding: 0;        
    }

    .grid-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}