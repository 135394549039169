@use "../../stylesheets/abstracts/functions";

.offer-nav {
    display: flex;
    z-index: var(--zindex-circle-nav);
    width: 100%;
    align-items: center;
    justify-content: center;

    .color-bar {
        position: absolute;
        width: 100%;
        height: functions.scaleH(30px);
        top: functions.scaleH(2130px);

        transition: background-color linear var(--ad-circle-nav);

        &.pax, &.pax_linefit {
            background-color: var(--offer-pax-primary);
        }

        &.app {
            background-color: var(--offer-app-primary);
        }

        &.msp {
            background-color: var(--offer-msp-primary);
        }

        &.diy {
            background-color: var(--offer-diy-primary);
        }

        &.skywave {
            background-color: var(--offer-skywave-primary);
        }

        &.display_360, &.display_360_linefit {
            background-color: var(--offer-360-primary);
        }
    }



    .offer-nav-buttons {
        /*
        position: absolute;
        top: functions.scaleH(1843px);
        right: functions.scaleW(370px);
        */
        display: flex;
        gap: functions.scaleH(68px);
        align-items: flex-end;
        margin-bottom: functions.scaleH(68px);
        width: 100%;

        .nav-title {
            width: functions.scaleW(2054px);;
            text-align: right;
            font-family: 'text-base';
            font-style: normal;
            font-weight: 800;
            line-height: functions.scaleW(110px);
            font-size: functions.scaleW(128px);
            padding-right: functions.scaleW(17px);

            transition: background-color linear var(--ad-circle-nav);

            background: linear-gradient(90deg, var(--offer-unselected) 37.76%, rgba(247, 247, 247, 0.47) 98%);
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
    
            &.pax, &.pax_linefit {
                background: linear-gradient(90deg, var(--offer-pax-primary) 37.76%, rgba(247, 247, 247, 0.47) 98%);
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
            &.app {
                background: linear-gradient(90deg, var(--offer-app-primary) 37.76%, rgba(247, 247, 247, 0.47) 98%);
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
            &.msp {
                background: linear-gradient(90deg, var(--offer-msp-primary) 37.76%, rgba(247, 247, 247, 0.47) 98%);
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
            &.diy {
                background: linear-gradient(90deg, var(--offer-diy-primary) 37.76%, rgba(247, 247, 247, 0.47) 90.88%);
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
            &.skywave {
                background: linear-gradient(90deg, var(--offer-skywave-primary) 37.76%, rgba(247, 247, 247, 0.47) 98%);
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
            &.display_360, &.display_360_linefit {
                background: linear-gradient(90deg, var(--offer-360-primary) 37.76%, rgba(247, 247, 247, 0.47) 98%);
                background-clip: text;
                text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
        }
        
   

    }
    .triangle {
        position: absolute;
        // not botom because other reso
        top: functions.scaleH(2106px);

        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 calc(30px * var(--coef-w)) calc(30px * var(--coef-h)) calc(30px * var(--coef-w));
        border-color: transparent transparent gold transparent;

        transition: border-color linear var(--ad-circle-nav), left linear var(--ad-circle-nav);

    }

}

.offer-buton {
    padding: 0;
    border: 0;
    cursor: pointer;
    width: functions.scaleW(174px);
    height: functions.scaleH(241px);
    background-size: contain;

    &.pax, &.pax_linefit {
        background: url("../../../public/images/pax.png") no-repeat;
        background-size: contain;
        transform: scale(1.0);
    }            
    &.display_360, &.display_360_linefit {
        background: url("../../../public/images/360.png") no-repeat;
        background-size: contain;
    }            
    &.app {
        background: url("../../../public/images/app.png") no-repeat;
        background-size: contain;
    }            
    &.msp {
        background: url("../../../public/images/msp.png") no-repeat;
        background-size: contain;
    }            
    &.diy {
        background: url("../../../public/images/diy.png") no-repeat;
        background-size: contain;
    }            
    &.skywave {
        background: url("../../../public/images/psw.png") no-repeat;
        background-size: contain;
    }            
    /*
    &.first {
        transform: scale(1.3);
    }
    */

}