@use "../stylesheets/abstracts/functions";



.offer {



    //style={{position:"absolute", left:"1058px", top:"-94px", visibility:"hidden"}
    .satellite {
        position: absolute;
        left: functions.scaleW(1058px);
        top: functions.scaleH(-94px);
        z-index: 2000;
        cursor: pointer;
    }

    .zone-focus-1 {
        position: absolute;
        visibility: hidden;
    }
}


.space-zone {
    position: absolute;
    z-index: 50;
    .satellite {
        pointer-events: all;
        position: absolute;
        left: functions.scaleW(1058px);
        top: functions.scaleH(-94px);
        z-index: 2000;
        cursor: pointer;
        .zone-focus-1 { 
            position: absolute;
            left: functions.scaleW(-20px);
            top: functions.scaleH(200px);
        }
    }

}